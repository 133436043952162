import { useEffect, Suspense } from "react";
import { Route, Switch } from "react-router";
import { useLocation } from "react-router-dom";
import { publicRoutes } from "./route";
import { ErrorBoundary } from "react-error-boundary";
import Logger from "../logger";
import LoadingLazy from "./LoadingLazy";
import * as CONFIG from "@iso/config/index";
import { useQuery } from "../hooks";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useCommonActions } from "@iso/actions/common.actions";

function AppRoutes() {
   const location = useLocation();
   const { i18n } = useTranslation();
   const { getStoreByStoreCode } = useCommonActions();
   const query = useQuery();
   const { storeCode } = useParams();

   useEffect(async () => {
      if (storeCode) {
         await getStoreByStoreCode(storeCode);
      }
   }, [storeCode]);

   useEffect(() => {
      const lang = query.get("lang");
      if (lang) {
         switch (lang) {
            case CONFIG.LANG.VI:
               i18n.changeLanguage(lang);
               localStorage.setItem("lang", lang);
               return;
            case CONFIG.LANG.EN:
               i18n.changeLanguage(lang);
               localStorage.setItem("lang", lang);
               return;
            case CONFIG.LANG.JA:
               i18n.changeLanguage(lang);
               localStorage.setItem("lang", lang);
               return;
            case CONFIG.LANG.AS:
               i18n.changeLanguage(lang);
               localStorage.setItem("lang", lang);
               return;
            case CONFIG.LANG.KOR:
               i18n.changeLanguage(lang);
               localStorage.setItem("lang", lang);
               return;
         }
      }
   }, [query]);

   useEffect(() => {
      document
         .getElementById("my-manifest-placeholder")
         .setAttribute(
            "href",
            `${CONFIG.API_FEEDBACK}/webstore/manifest?st=4ps&url=${location.pathname}`
         );
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [location.pathname]);

   return (
      <Suspense fallback={<LoadingLazy />}>
         <Switch>
            {publicRoutes.map((route, index) => (
               <Route key={index} path={route.path} exact={route.exact}>
                  <ErrorBoundary
                     FallbackComponent={({ error, resetErrorBoundary }) => (
                        <div role="alert">
                           <p>Something went wrong:</p>
                           <pre>{error.message}</pre>
                           <button onClick={resetErrorBoundary}>
                              Try again
                           </button>
                        </div>
                     )}
                     onError={(error) => {
                        Logger.error({
                           label:
                              window.navigator.userAgentData?.platform ||
                              "undefinded",
                           level: "error",
                           message: error.toString(),
                           source: CONFIG.SOURCE_NAME_LOGGING,
                        });
                     }}
                     onReset={() => {}}
                  >
                     <route.component />
                  </ErrorBoundary>
               </Route>
            ))}
         </Switch>
      </Suspense>
   );
}

export default AppRoutes;
