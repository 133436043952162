import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { RecoilRoot } from "recoil";
import { initFirebase } from "./utils/firebase";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import App from "App";
import "./i18n";
import "@iso/assets/style/antd.scss";
import "./index.css";

initFirebase();

ReactDOM.render(
   <React.StrictMode>
      <I18nextProvider i18n={i18next}>
         <RecoilRoot>
            <BrowserRouter>
               <App />
            </BrowserRouter>
         </RecoilRoot>
      </I18nextProvider>
   </React.StrictMode>,
   document.getElementById("app")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.register();
