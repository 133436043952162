/* eslint-disable import/no-extraneous-dependencies */
import firebase from "firebase/app";
import "firebase/auth";
import { firebaseConfig } from "../config/firebase";

export const initFirebase = () => {
   if (!firebase.apps.length) {
      firebase.initializeApp(firebaseConfig);
   }
};

export const getFirebaseToken = async () => {
   const { currentUser } = firebase.auth();
   if (currentUser) return currentUser.getIdToken();

   // time out 10s if too slow
   return new Promise((resolve, reject) => {
      const waitTimer = setTimeout(() => {
         reject();
      }, 10000);

      const unregisterAuthObserver = firebase
         .auth()
         .onAuthStateChanged(async (user) => {
            if (!user) {
               reject();
            }
            // Refresh token
            const token = await user.getIdToken();
            resolve(token);
            unregisterAuthObserver();
            clearTimeout(waitTimer);
         });
   });
};
