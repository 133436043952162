import { atom } from "recoil";

const dineInFeedbackAtom = atom({
   key: "dineInFeedback",
   default: {
      store: {
         list: [],
         storeDetail: "",
         isShowChooseStore: false,
         current: null,
      },
      partner: {
         list: [],
         current: null,
      },
      area: {
         list: [],
         current: null,
      },
      redInvoice: {
         taxCode: "",
         name: "",
         address: "",
         email: "",
      },
      auth: {
         user: "",
         pass: "",
      },
      bill: { tableName: "", tableNo: "", info: null },
      loading: false,
   },
});

export { dineInFeedbackAtom };
