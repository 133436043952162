import React from "react";
import { ReactComponent as Logo } from "@iso/assets/images/logo.svg";

function LoadingLazy() {
   return (
      <div className="loading-routes w-screen h-screen bg-primary-500 flex items-center justify-center">
         <div className="animate-bounce">
            <Logo />
         </div>
      </div>
   );
}

export default LoadingLazy;
