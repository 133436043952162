import { createLogger, transports } from "winston";

export default createLogger({
   level: "debug",
   transports: [
      new transports.Http({
         host: "api.pizza4ps.com",
         port: 443, // but this defaults to port 80
         path: "/api/migration-delivery-iot/fe-log",
         ssl: true,
      }),
   ],
});
