// eslint-disable-next-line import/no-extraneous-dependencies
import firebase from "firebase/app";
import { firebaseConfig } from ".";

export const firebaseAuthConfig = {
   signInFlow: "popup",
   signInSuccessUrl: "/auth",
   signInOptions: [
      {
         provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
         scopes: ["https://www.googleapis.com/auth/userinfo.profile"],
         requireDisplayName: true,
         customParameters: {
            prompt: "select_account",
         },
      },
   ],
   callbacks: {
      // Avoid redirects after sign-in.
      signInSuccessWithAuthResult: () => true,
   },
};

export { firebaseConfig };
