import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Fetch from "i18next-fetch-backend";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
   .use(Fetch)
   .use(LanguageDetector)
   .use(initReactI18next)
   .init({
      backend: {
         loadPath: function (lng, ns) {
            return `/locales/${lng}/${ns}.json`;
         },
      },
      fallbackLng: "en",
      lng: "en",
      debug: false,
      ns: ["common"],
      defaultNS: "common",
      keySeparator: true,
      interpolation: {
         escapeValue: false,
         formatSeparator: ",",
      },
      react: {
         useSuspense: false,
         wait: true,
      },
   });
export default i18n;
