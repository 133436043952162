import { message } from "antd";
import axios from "axios";
import firebase from "firebase";
import Logger from "../logger";
import * as CONFIG from "@iso/config/index";

export const axiosClient = axios.create({
   headers: {
      Accept: "application/json",
   },
});

axiosClient.interceptors.request.use(async (config) => {
   const currentUser = firebase.auth().currentUser;
   if (currentUser) {
      const token = await currentUser.getIdToken();
      config.headers.Authorization = `Bearer ${token}`;
   }

   const userInfo = JSON.parse(sessionStorage.getItem("info_user"));
   console.log("User info: ", userInfo);
   if (userInfo?.pass) {
      config.headers.pass = userInfo?.pass;
      config.headers.user = userInfo?.user;
   }
   return config;
});

export const setUserInfoHeader = () => {};

axiosClient.interceptors.response.use(
   (response) => {
      if (response && response.data) {
         return response.data;
      }
   },
   (error) => {
      const mess = error?.response?.data?.message;
      const query = new URLSearchParams(window.location.search);
      console.log(`query.get("skip_check_bill")`, query.get("skip_check_bill"));
      if (!window.location.href.includes("/feedback-bathroom/")) {
         if (
            query.get("skip_check_bill") === "true" &&
            mess === "BILL_NOT_FOUND"
         ) {
            return;
         }
         message.error(mess);
      }
      try {
         Logger.error({
            label: window?.navigator?.userAgentData?.platform || "undefinded",
            level: "error",
            message: `${
               error?.response ? JSON.stringify(error?.response) : ""
            } \n ${error?.toString() || ""}`,
            source: CONFIG.SOURCE_NAME_LOGGING,
         });
      } catch (error) {}
      return Promise.reject(error);
   }
);
